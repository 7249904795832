<template>
  <div>
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>

          <CCardHeader>
            <strong class="card-title mr-3"> {{$t("articleLinkListPage.pageTitle")}}</strong>
            <CButton
              color="info"
              @click="onCreateClicked()"
            >{{
              $t("common.create")
            }}</CButton>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <!-- #region Table -->
              <table class="edge-type-table table table-sm table-striped table-bordered">
                <thead class="bg-light text-body text-center">
                  <tr>
                    <th width="120">ID</th>
                    <th>{{ $t("articleLinkListPage.title") }}</th>
                    <th width="300">{{ $t("articleLinkListPage.facilityList") }}</th>
                    <th width="120">{{ $t("common.switchActive") }}</th>
                    <th width="120">{{ $t("common.action") }}</th>
                  </tr>
                </thead>

                <Draggable
                  class="table-row-drag"
                  v-model="tableItems"
                  @end="onSortEnd"
                  v-bind="dragOptions"
                  v-if="canShowTable"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                    tag="tbody"
                  >
                    <tr
                      v-for="item in tableItems"
                      :key="item.id"
                    >

                      <!-- #region ID -->
                      <td>
                        {{ item.id }}
                      </td>
                      <!-- #endregion ID -->

                      <!-- #region 特集タイトル -->
                      <td>
                        <CLink
                          :href="item.url"
                          target="_blank"
                        >
                          {{ item.title }}
                        </CLink>
                      </td>
                      <!-- #endregion 特集タイトル -->

                      <!-- #region 特集URL -->
                      <td>
                        {{ item.facilityListName }}
                      </td>
                      <!-- #endregion 特集URL -->

                      <!-- #region 表示／非表示 -->
                      <td class="text-center">
                        <CSwitch
                        class="swc sw-2"
                          color="info"
                          variant="opposite"
                          :labelOn="$t('common.active')"
                          :labelOff="$t('common.inActive')"
                          :checked="item.status | masterStatus"
                          @update:checked="onStatusChanged(item)"
                        />
                      </td>
                      <!-- #endregion 表示／非表示 -->

                      <!-- #region アクション -->
                      <td class="text-center">
                        <CButton
                          color="info"
                          square
                          size="sm"
                          @click="onEditClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i>{{ $t("common.edit") }}</CButton>
                      </td>
                      <!-- #endregion アクション -->

                    </tr>
                  </transition-group>
                </Draggable>
              </table>
              <!-- #endregion Table -->

            </CForm>
          </CCardBody>

          <transition name="fade">
            <CRow v-if="!canShowTable">
              <CCol>
                <div class="d-flex justify-content-center">
                  <CAlert
                    color="danger"
                    class="w-50 p-4 text-center"
                  >
                    <h5>{{ $t("articleLinkListPage.notFound") }}</h5>
                  </CAlert>
                </div>
              </CCol>
            </CRow>
          </transition>
        </CCard>
      </CCol>
    </CRow>

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
export default {
  name: "articleLinkList",

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },

    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },


  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag

      tableItems: [],
      order: [],
      response: [],
      errorMessage: "",
    };
  },

  methods: {
    //#region Event
    onStatusChanged(item) {
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    onEditClicked(id) {
      this.$router.push({ path: `/admin/articleLink/${id}/edit` });
    },

    onCreateClicked() {
      this.$router.push({ path: `/admin/articleLink/create` });
    },

    onSortEnd() {
      // 一覧表の表示順IDを昇順の値にする
      this.tableItems.forEach((a, i) => a.displayOrder = this.order[i]);
      this.reqPutOrder();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      const callback = (a) => {
        this.updateTable(a.articleLinks);
        this.createDisplayOrderIdAscList(a.articleLinks);
        this.loading = false;
      };

      this.reqGetArticleLinkAll(callback);
    },

    reqPutStatus(item) {
      const body = { status: item.status };

      const callback = () => {
        this.reqGet();
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutArticleLinkStatus(item.id, body, callback, errorCallback);
    },

    reqPutOrder() {
      const list = this.tableItems.map(a => { return { id: a.id, displayOrder: a.displayOrder }; });
      this.debugLog(list);
      const body = { list };

      const callback = () => {
        this.successModal = true;
        this.reqGet();
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutArticleLinkOrder(body, callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    /**
     * 一覧表更新
     * @param datas
     */
    updateTable(datas) {
      this.clearTable();
      datas
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((data) => {
          this.tableItems.push({
            id: data.id,
            title: data.title,
            url: data.url,
            facilityList: data.facilityList,
            facilityListId: data.facilityListId,
            facilityListName: data.facilityListName,
            description: data.description,
            status: data.status,
            displayOrder: data.displayOrder,
          });
        });
    },

    /** 一覧表をクリア */
    clearTable() {
      this.tableItems = [];
    },

    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(item) {
      this.order = [];
      item.forEach((a) => this.order.push(a.id));
      this.order.sort((a, b) => a - b);
    },

    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>